import PropTypes from 'prop-types';
// guards
// components
import { getMessaging, onMessage } from 'firebase/messaging';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { firebaseApp } from '../contexts/FirebaseContext';
import useFcmToken from '../hooks/useFcmToken';
import { subcribeTokenToAppTopicAPI } from '../service/gplx.fcm.service';
import LogoOnlyLayout from './LogoOnlyLayout';
import MainLayout from './main';
// ----------------------------------------------------------------------

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['main', 'logoOnly']),
};


export default function Layout({ variant = 'main', children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { fcmToken, notificationPermissionStatus } = useFcmToken();

  // Use the token as needed
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(firebaseApp);
      const unsubscribe = onMessage(messaging, (payload) => {
        enqueueSnackbar(payload.notification.body, { variant: "info", autoHideDuration: null });
      });
      return () => {
        unsubscribe(); // Unsubscribe from the onMessage event
      };
    }
  }, []);

  useEffect(() => {
    if (notificationPermissionStatus === "granted" && fcmToken)
      updateTokenToBE(fcmToken);
  }, [fcmToken, notificationPermissionStatus]);

  async function updateTokenToBE(token) {
    await subcribeTokenToAppTopicAPI(token);
  }

  if (variant === 'logoOnly') {
    return <LogoOnlyLayout> {children} </LogoOnlyLayout>;
  }

  return <MainLayout>{children}</MainLayout>;
};
