import { handleRequest } from "../utils/axios";

export const subcribeTokenToAppTopicAPI = async (token) => {
  const config = {
    url: '/fcm/subcribe/topic',
    method: 'PUT',
    data: { fcmToken: token, topic: "app" }
  };

  return handleRequest(config);
};