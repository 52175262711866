import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// next
import Head from 'next/head';
// @mui
import { Box } from '@mui/material';
import { useRouter } from 'next/router';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', description = "", imagePath = "https://onthibanglaixe.net/logo/banner-onthibanglaixe.jpg", meta, ...other }, ref) => {
  const { asPath } = useRouter();
  const pathname = `https://onthibanglaixe.net${asPath}`;

  return (
    <>
      <Head>
        <title>{title} | OnThiBangLaiXe.net</title>
        <meta name="description" content={description} />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={imagePath} />
        <meta property="og:url" content={pathname} />

        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="OnThiBangLaiXe.net" />
        <meta property="fb:app_id" content="632228391747595" />

        {meta}
      </Head >

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  imagePath: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
