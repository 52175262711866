// ----------------------------------------------------------------------
export const PATH_AUTH = {
  login: '/login',
  register: '/register',
  profile: '/auth/profile',
  setting: "/auth/setting",
  dashboard: "/auth/dashboard"
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_THIGPLX = {
  situations: '/thi-tinh-huong',
  car: '/thi-gplx/o-to',
  bike: '/thi-gplx/xe-may',
  practicalExam: '/thi-sa-hinh',
  realExam: '/thi-gplx/thi-that',
  questions: '/questions',
  blog: '/tin-hay',
  home: '/',
  street: '/thi-duong-truong/',
  education:'/day-lai-xe'
};
export const PATH_RESOURCE = {
  blogs: '/tin-hay',
  questions: '/questions',
  shorts: '/shorts',
};