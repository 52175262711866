// next
import {
  Container, Divider, Grid, Link,
  Stack, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
// routes
import { PATH_THIGPLX } from '../../routes/paths';
// components
import Script from 'next/script';
import Image from "../../components/Image";
import SocialsButton from '../../components/SocialsButton';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'GPLX Ô tô',
    children: [
      { name: '600 câu lý thuyết', href: PATH_THIGPLX.car },
      { name: '120 tình huống', href: PATH_THIGPLX.situations },
      { name: 'Thi sa hình', href: PATH_THIGPLX.practicalExam },
    ],
  },
  {
    headline: 'GPLX Xe máy',
    children: [
      { name: '200 câu lý thuyết', href: PATH_THIGPLX.bike },
      { name: 'Thi sa hình', href: PATH_THIGPLX.practicalExam },
      { name: 'Thi thật', href: PATH_THIGPLX.realExam },
    ],
  },
  {
    headline: 'Điều khoản',
    children: [
      { name: 'Điều khoản sử dụng', href: '/dieu-khoan-su-dung' },
      { name: 'Chinh sách riêng tư', href: '/chinh-sach-quyen-rieng-tu' },
      { name: 'Về chúng tôi', href: '/ve-chung-toi' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  let { translate } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 6, mb: 1 }} maxWidth={themeStretch ? 'xl' : 'lg'}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={5}>
            <Stack direction={"row"} maxWidth={300} spacing={2} mb={2}>
              <Link href="/tai-app/" target="_blank" >
                <Image src={"/icons/ic_ios.jpg"} alt="ios" />
              </Link>
              <Link href="/tai-app/" target="_blank" >
                <Image src={"/icons/ic_android.jpg"} alt="android" />
              </Link>
            </Stack>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate("index.description")}
            </Typography>
            <Typography variant="subtitle2" sx={{ pr: { md: 5 } }}>
              Nhận HSLX: 098.707.2219
            </Typography>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 2, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} links={{
                facebook: 'https://www.facebook.com/onthibanglaixe',
                youtube: "https://www.youtube.com/@onthibanglaixe",
                tictok: "https://www.tiktok.com/@onthibanglaixe.net"
              }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-around"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link color="inherit" variant="body2" sx={{ display: 'block' }} key={link.name} href={link.href} >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Stack spacing={1} my={2} alignItems={"center"} justifyContent={"center"}>
          <Typography component="p" variant="body2" >
            ©{new Date().getFullYear()}. by <a href="https://jmaster.io" target='_blank' rel="noreferrer">JMaster IO</a>
          </Typography>
          <a href="//www.dmca.com/Protection/Status.aspx?ID=86c6be9b-632c-409b-9123-c35c61beae0d" title="DMCA.com Protection Status" className="dmca-badge">
            <img height={20} width={100} src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-08.png?ID=86c6be9b-632c-409b-9123-c35c61beae0d" alt="DMCA.com Protection Status" />
          </a>
          <Script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js" />
        </Stack>
      </Container>
    </RootStyle>
  );
}
