import PropTypes from 'prop-types';
// next
// @mui
import { Box, Stack } from '@mui/material';
// components
//
import { Messenger } from 'react-live-chat-loader';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function MainLayout({ children }) {

  return (
    <>
      <Messenger />
      <Stack sx={{ minHeight: 1 }}>
        <MainHeader />
        {children}
        <Box sx={{ flexGrow: 1 }} />
        <MainFooter />
      </Stack>
    </>
  );
}
