// routes
import { PATH_THIGPLX } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'menu.car',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.car,
    children: [{
      subheader: 'Lý thuyết Ô tô',
      items: [
        { title: 'Lý thuyết Ô tô', path: PATH_THIGPLX.car },
        { title: 'Thử thi thật', path: PATH_THIGPLX.realExam },
      ]
    },
    {
      subheader: 'Thực hành Ô tô',
      items: [
        { title: 'Thi mô phỏng', path: PATH_THIGPLX.situations },
        { title: 'Đi sa hình', path: PATH_THIGPLX.practicalExam },
        { title: 'Đi đường trường', path: PATH_THIGPLX.street },
      ]
    },
    {
      subheader: 'Mobile App',
      items: [
        { title: 'Tải app', path: '/tai-app' },
      ]
    }
    ],
  },
  {
    title: 'menu.bike',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.bike,
    children: [{
      subheader: 'Lý thuyết xe máy',
      items: [
        { title: 'Lý thuyết xe máy', path: PATH_THIGPLX.bike },
        { title: 'Thử thi thật', path: PATH_THIGPLX.realExam },
      ]
    },
    {
      subheader: 'Thực hành xe máy',
      items: [
        { title: 'Đi sa hình', path: PATH_THIGPLX.practicalExam },
      ]
    },
    {
      subheader: 'Mobile App',
      items: [
        { title: 'Tải app', path: '/tai-app' },
      ]
    }
    ],
  },
  {
    title: 'menu.situation',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.situations,
  },
  // {
  //   title: 'menu.practicalExam',
  //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
  //   path: PATH_THIGPLX.practicalExam,
  // },
  {
    title: 'menu.education',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.education,
  },
  {
    title: 'menu.question',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.questions
  },
  {
    title: 'menu.blog',
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: PATH_THIGPLX.blog,
  },
];

export default menuConfig;
